import React, { useState, useEffect } from 'react';

import axios from 'axios';

import main from '../../img/Contacts/main.jpg'
import mainmobile from '../../img/Contacts/mainmob.jpg'

import main1 from '../../img/Contacts/main1.svg'
import main2 from '../../img/Contacts/main2.svg'
import main3 from '../../img/Contacts/main3.svg'
import main4 from '../../img/Contacts/main4.svg'

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

function Main () {

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const openModal = () => {
        setIsOpen(true);
        setIsSubmitted(false);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [isOpen]);

    const validate = () => {
        const newErrors = {};
        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
        }
        if (!name) {
            newErrors.name = 'Name is required';
        }
        if (!phone) {
            newErrors.phone = 'Phone is required';
        } else if (!/^\d+$/.test(phone)) {
            newErrors.phone = 'Phone is invalid';
        }
        return newErrors;
    };

    const handleSubmit = async () => {
        const validationErrors = validate();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await axios.post('https://civbt.xyz/api/bot/qMmpuiTAXT', {
                    email,
                    name,
                    phone
                });
                console.log('Form submitted successfully:', response.data);
                setIsSubmitted(true);
                setEmail('');
                setName('');
                setPhone('');
            } catch (error) {
                console.error('Error submitting the form:', error);
            }
        }
    };

    return(
        <>
        {isOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-[999]">
                <div className="bg-white p-6 rounded-lg shadow-lg text-center relative xl:w-[500px] flex flex-col items-center">
                    <button 
                        onClick={closeModal} 
                        className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-2xl">
                        &times;
                    </button>
                    {!isSubmitted ? (
                        <>
                            <h1 className="text-2xl font-bold mb-4">{t('contacts.1')}</h1>
                            <p className="mb-4">{t('contacts.2')}</p>
                            <div className="w-full">
                                <input 
                                    type="text" 
                                    placeholder="E-Mail" 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={`px-[20px] border rounded-[16px] border-[#6E7882] h-[60px] w-full helvetica ${errors.email ? 'border-red-500' : ''}`}
                                />
                                {errors.email && <p className="text-red-500 text-left">{errors.email}</p>}
                                <input 
                                    type="text" 
                                    placeholder="Имя" 
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className={`px-[20px] border rounded-[16px] border-[#6E7882] h-[60px] w-full helvetica mt-[10px] ${errors.name ? 'border-red-500' : ''}`}
                                />
                                {errors.name && <p className="text-red-500 text-left">{errors.name}</p>}
                                <input 
                                    type="text" 
                                    placeholder="Телефон" 
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    className={`px-[20px] border rounded-[16px] border-[#6E7882] h-[60px] w-full helvetica mt-[10px] ${errors.phone ? 'border-red-500' : ''}`}
                                />
                                {errors.phone && <p className="text-red-500 text-left">{errors.phone}</p>}
                                <div 
                                    className="w-full h-[60px] rounded-[16px] bg-[#F75524] mt-[10px] cursor-pointer flex items-center justify-center"
                                    onClick={handleSubmit}
                                >
                                    <p className="helvetica text-white">{t('contacts.3')}</p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="text-center">
                            <h1 className="text-2xl font-bold mb-4">{t('contacts.4')}</h1>
                            <p className="mb-4">{t('contacts.5')}</p>
                            <div 
                                className="w-full h-[60px] rounded-[16px] bg-[#F75524] mt-[10px] cursor-pointer flex items-center justify-center"
                                onClick={closeModal}
                            >
                                <p className="helvetica text-white">{t('contacts.6')}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )}
        <div className="relative overflow-hidden">
            <div className="max-w-[1280px] mx-auto relative">
                <div className="flex justify-center xl:justify-normal">
                    <div className="absolute">
                        <Fade delay={500}><p className="helvetica mt-[98px] xl:mt-[253px] text-white text-[40px] xl:text-[74px] leading-[1.2] text-center xl:text-start">{t('header.3')}</p></Fade>
                        <Fade delay={700}><p className="inter text-[14px] xl:text-[18px] text-white mt-[20px] text-center xl:text-start max-w-[509px] mx-auto xl:mx-0">{t('contacts.add')}</p></Fade>
                        <div className="flex flex-wrap mt-[50px] xl:mt-[100px] gap-[20px] mx-[20px] xl:mx-0">
                            <div onClick={openModal} className="w-[282px] h-[136px] rounded-[40px] bg-[#FF3E0480] flex justify-center items-center gap-[10px] flex-grow-[1] cursor-pointer">
                                <img src={main3} alt="" className="w-[54px] h-[54px]"/>
                                <div>
                                    <p className="font-[700] text-white text-[18px] inter">{t('contacts.7')}</p>
                                    <p className="max-w-[171px] text-[14px] text-white leading-[1.6] mt-[5px]">{t('contacts.8')}</p>
                                </div>
                            </div>  
                            <div className="w-[282px] h-[136px] rounded-[40px] bg-[#FF3E0480] flex justify-center items-center gap-[10px] flex-grow-[1]">
                                <img src={main2} alt="" className="w-[54px] h-[54px]"/>
                                <div>
                                    <a href="mailto:info@bridgeinvest.com" class="font-[700] text-white text-[18px] inter">info@bridgeinvest.com</a>
                                    <p className="max-w-[171px] text-[14px] text-white leading-[1.6] mt-[5px]">{t('contacts.9')}</p>
                                </div>
                            </div>  
                            <div className="w-[282px] h-[136px] rounded-[40px] bg-[#FF3E0480] flex justify-center items-center gap-[10px] flex-grow-[1]">
                                <img src={main1} alt="" className="w-[54px] h-[54px]"/>
                                <div>
                                    <p className="font-[700] text-white text-[18px] inter">+441252437014</p>
                                    <p className="max-w-[171px] text-[14px] text-white leading-[1.6] mt-[5px]">{t('contacts.10')}</p>
                                </div>
                            </div>                      
                        </div>
                    </div>
                </div>
            </div>
            <img src={main} alt="" className="w-full object-cover hidden md:block h-[900px]"/>
            <img src={mainmobile} alt="" className="w-full block md:hidden h-[1050px]"/>
        </div>
        </>
    );
}

export default Main;