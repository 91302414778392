import React from "react";

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import main from '../../img/Home/main.jpg'
import mainmobile from '../../img/Home/main-phone.jpg'

import Fade from 'react-reveal/Fade'

function Main () {
 
    const { t } = useTranslation();

    return(
        <div className="relative overflow-hidden">
            <div className="max-w-[1280px] mx-auto relative">
                <div className="flex justify-center xl:justify-normal">
                    <div className="absolute">
                        <Fade><p className="text-[#6E7882] mt-[98px] xl:mt-[253px] text-center xl:text-start">Bridge Invest Market: </p></Fade>
                        <Fade delay={500}><p className="helvetica text-white text-[40px] xl:text-[74px] leading-[1.2] mt-[6px] text-center xl:text-start"><span className="text-[#F75524]">{t('main.1')}</span><br/>{t('main.2')}<br/>{t('main.3')}</p></Fade>
                        <Fade delay={700}><p className="inter text-[14px] xl:text-[18px] text-white mt-[20px] text-center xl:text-start">{t('main.4')}</p></Fade>
                        <div className="flex flex-col xl:flex-row mt-[30px] xl:mt-[155px] gap-[20px]">
                            <Fade delay={300}><Link to="https://user.bridgeinvestmarket.com/"><div className="w-[282px] h-[60px] flex items-center justify-center bg-[#16C768] rounded-[16px] cursor-pointer mx-auto xl:mx-0">
                                <p className="inter text-[18px] text-white">{t('main.5')}</p>
                            </div></Link></Fade>
                            <Fade delay={600}><Link to="https://user.bridgeinvestmarket.com/"><div className="w-[282px] h-[60px] flex items-center justify-center border-[#16C768] border rounded-[16px] cursor-pointer mx-auto xl:mx-0">
                                <p className="inter text-[18px] text-[#16C768]">{t('main.6')}</p>
                            </div></Link></Fade>
                        </div>
                    </div>
                </div>
            </div>
            <Fade right><div className="bg-[#000] cursor-pointer rounded-[40px] w-[690px] h-[368px] absolute md:right-[-100px] 2xl:right-[-50px] md:bottom-[-60px] 2xl:bottom-[47px] hidden md:block ">
                <div className="flex overflow-hidden"> 
                    <iframe className="ml-[40px]" width="600" height="315" src="https://www.youtube.com/embed/iEpJwprxDdk?si=peRTWaCqg9sE_gKY&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div></Fade>
            <img src={main} alt="" className="w-full h-[1010px] object-cover hidden md:block"/>
            <img src={mainmobile} alt="" className="w-full block md:hidden"/>
        </div>
    );
}

export default Main;